import './Info.css'
function Info() {
    return (
        <section className='info-container'>
            <div className='item'>
                <h1>Campaign</h1>
                <img className='campain-image' src='/images/about-img-header.jpeg' alt=''/>
            </div>
            <div className='about-container'>
                <h1>About us</h1>
                <div className='text-content'>
                    <p >New Mission of Hope Haitian Baptist Church began in June of 2003.
                    Our goal is to positively impact the
                    lives of Haiti's population spiritually, physically and emotionally.
                    Today, together with our generious friends and partners, Mission of Hope provides a high-quality,
                    Christ-centered education to 800 children on two campuses.
                    We feed the hungry, assist orphans, create sustainable and
                    temporary employment opportunities and provide healthcare to
                    remote regions and the people who need it most.
                    Haiti is in our hearts. Since you're here,
                    it must be in yours, too. Click around to
                    learn more about how you can be a part of the change happening now.
                 </p>
                </div>
            </div>
            <div className='item live-church'>
                <h1>Live Church Service</h1>
                <img className='coming-soon-image' src='images/live.jpg' alt=''/>
            </div>
        </section>
    );
}

export default Info;