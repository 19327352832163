import {
  useNavigate,
} from 'react-router-dom';
import './Quote.css'
function Quote() {
  const navigate = useNavigate();
  const handleClick = () => {
    console.log('handleClick')   
     navigate('/contact')
  }
    return (
        <section className='quote-container'>
            <div className='live-radio'>
                <h1>Live radio</h1>
                <iframe 
                src="https://indiegospel.net/RADIO/player/index.html" 
                title="Live Radio"
                style={{border:'none'}}
                />
            </div>
            <div className='verse-item'>
                <div className='verse-day'>
                    <h1>Verse of the day</h1>
                    <p>
                        “Teach us to number our days, that we may gain a heart of wisdom.” -Psalm 90:12 Listen to chapter
                    </p>
                </div>
            </div>
            <div className='quote-item'>
                <div className='quote-day'>
                    <h1>Quote of the Day</h1>
                    <p>"Every charitable act is a stepping stone toward heaven."
                    </p>
                </div>
            </div>
            <div className='join-us-item'>
                <div className='join-us' onClick={handleClick}>
                    <h1>Joing us</h1>
                    <p>
                        Click here to Join Us
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Quote;