
import './CommonHeader.css'
function CommonHeader() {
 return (
  <div className="about-img-header" style={{backgroundImage: "url(/images/about-img-header.jpeg)"}}>
   <h1>YOU CAN MAKE A CHANGE IN THE LIFE OF ONE CHILD TODAY. VOLUNTEER NOW!</h1>
  </div>
 );
}

export default CommonHeader;
