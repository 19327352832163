
import { useCallback, useEffect, useState } from 'react';
import './Carousel.css'
function Carousel() {
    let [slideIndex, setSlideIndex] = useState(1)
    function plusSlides(n) {
        if (slideIndex > 3) {
            n = 1
        } else if (slideIndex == 1) {
            n = 3
        }
        showSlides(setSlideIndex(slideIndex + n));
    }

    function currentSlide(n) {
        showSlides(setSlideIndex(n));
    }

    console.log('slideIndex',slideIndex)

    const showSlides = useCallback((n)=>    {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) { slideIndex = 1 }
      if (n < 1) { slideIndex = slides.length }
      for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].className += " active";
  },[])
 
    useEffect(() => {
        showSlides(slideIndex);
    }, [showSlides,slideIndex]);

    return (
        <div className="slideshow-container">

            <div className="mySlides fade">
                <div className="numbertext">1 / 3</div>
                <img className='image' src='/images/dame.jpeg' style={{ width: "100%" }} alt='' />
                <div className="text">Caption Text</div>
            </div>

            <div className="mySlides fade">
                <div className="numbertext">2 / 3</div>
                <img className='image' src='../images/children1.jpeg' style={{ width: "100%" }} alt='' />
                <div className="text">Caption Two</div>
            </div>

            <div className="mySlides fade">
                <div className="numbertext">3 / 3</div>
                <img className='image' src='/images/children.jpeg' style={{ width: "100%" }} alt='' />
                <div className="text">Caption Three</div>
            </div>

            <a className="prev" onClick={() => plusSlides(-1)}>❮</a>
            <a className="next" onClick={() => plusSlides(1)}>❯</a>
            <br />

            <div style={{ textAlign: "center" }}>
                <span className="dot" onClick={() => currentSlide(1)}></span>
                <span className="dot" onClick={() => currentSlide(2)}></span>
                <span className="dot" onClick={() => currentSlide(3)}></span>
            </div>
        </div>
    );
}

export default Carousel;