
import './Footer.css'
function Footer() {
 return (
  <footer className="copyright">
   <span>
    &copy;
    2023 newmissionhhc.org
   </span>
  </footer>
 );
}

export default Footer;