import { Link } from "react-router-dom";
import './About.css';
import CommonHeader from '../CommonHeader/CommonHeader'
function About() {
  return (
    <>
      <CommonHeader />
      <div className="about_container">
        <section className='volunteers-container'>
          <div className='volunteers-picture1-container'>
            <h2 className='volunteers-heading-label'>Volunteers</h2>
            <div className='volunteers-picture1'>
              <img className='volunteer-image' src='/images/about-img1.jpeg' alt='' />
              <img className='volunteer-image1' src='/images/about-img2.jpeg' alt='' />
              <img className='volunteer-image1' src='/images/about-img3.jpeg' alt='' />
            </div>
          </div>
          <div className='volunteers-picture2'>
            <div className='volunteers-picture-2'>
              <div>
                <img className='volunteer-image1' src='/images/about-img4.jpeg' alt='' />
              </div>
              <div>
                <img className='volunteer-image1' src='/images/about-img5.jpeg' alt='' />
              </div>

            </div>
            <Link to='/contact' className='btn-volunteer'>Join us</Link>
          </div>
        </section>
        <div className='about-paragraphs'>
          <h1>About us</h1>
          <p>
            You will find the latest information about us on this page. Our company is constantly evolving and growing. We provide wide range of services. Our mission is to provide best solution that helps everyone. If you want to contact us, please fill the contact form on our website. We wish you a good day! You will find the latest information about us on this page. Our company is constantly evolving and growing. We provide wide range of services. Our mission is to provide best solution that helps everyone. If you want to contact us, please fill...
          </p>

          <h2>Our vision</h2>
          <p>
            The contact form on our website. We wish you a good day! You will find the latest information about us on this page. Our company is constantly evolving and growing. We provide wide range of services. Our mission is to provide best solution that helps everyone. If you want to contact us, please fill the contact form on our website. We wish you a good day! You will find the latest information about us on this page. Our company is constantly evolving and growing. We provide wide range of services. Our mission is to provide best solution that helps everyone. If you want to contact us, please fill the contact form...
          </p>
          <p>On our website. We wish you a good day! You will find the latest information about us on this page. Our company is constantly evolving and growing. We provide wide range of services. Our mission is to provide best solution that helps everyone. If you want to contact us, please fill the contact form on our website. We wish...</p>
        </div>

      </div>
    </>
  );
}

export default About;
