import { useState,useEffect} from 'react';
import emailjs from '@emailjs/browser'
import * as emailkey from '../emailkey'
import CommonHeader from "../CommonHeader/CommonHeader";
import './Contact.css'
import SimpleMap from '../SimpleMap/SimpleMap';
function Contact() {
let [form,setForm] =useState({name:'',email:'',message:''});
let [loanding,setloanding] =useState(false);
let [submitMessage,setSubmitMessage] =useState({error:false,message:''});
let [showMessage,setshowMessage] =useState(false);

const onSubmit = (e) =>{
  e.preventDefault()
  setloanding(true)
  emailjs.sendForm(
    emailkey.SERVICE_ID, 
    emailkey.TEMPLATE_ID, 
    e.target,
    emailkey.PUBLIC_KEY
    )
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
       setForm({...form,message:''})
       setloanding(false);
       setshowMessage(true);
       setInterval(()=>setshowMessage(false),5000)
       setSubmitMessage({...submitMessage,error:false,message:'Message sent! thank you for contacting us.'})
    }, function(error) {
       console.log('FAILED...', error);
       setSubmitMessage({...submitMessage,error:true,message:'Message not sent! please try again later'})
    });
  
  }

  const handleChange=(e)=>{
    console.log(e.target.name)
  setForm({...form,[e.target.name]:e.target.value})
  }
 return (
  <>
   <CommonHeader />
   <div className="contact-container">
    <div className="contact-img" style={{backgroundImage: "url(/images/image-1.jpeg)"}}></div>
    <div className="contact-form">
     <h1>
      Contacts
     </h1>
     <p>
      You will find the latest information about us on this page. Our company is constantly evolving and growing. We provide wide range of services. Our mission is to provide best solution that helps everyone. If you want to contact us, please fill the contact form on our website. We wish you a good day! You will find the latest information about us on this page...
     </p>
     <div className="info">
      <div className="address">
       <strong>Address:</strong>
       <br />
       <span>New Mission Of Hope Haitian Church</span>
       <br />
       <p> Tampa  Florida USA</p>

      </div>
      <div className="form">
       <form id="contactForm" onSubmit={onSubmit}>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" placeholder="Your name.." 
        value={form.name}
        onChange={handleChange}/>

        <label htmlFor="email">Email</label>
        <input type="text" id="email" name="email" placeholder="Your email.."  value={form.email}
        onChange={handleChange}
        />

        <label htmlFor="message">Message</label>
        <textarea id="subject" name="message" placeholder="Write something.." style={{
         height: '200px'
        }} value={form.message}
        onChange={handleChange}>
        </textarea>
        <input type="submit" value={loanding?'Sending...':'Submit'} disabled={loanding || (!form.message||!form.name ||!form.email)}/>
        {showMessage &&<span className={submitMessage.error?'err-msg':'success-msg'}>{submitMessage.message}</span>}
       </form>
      </div>
      {/* <SimpleMap/> */}
     </div>
    </div>

   </div>
  </>
 );
}

export default Contact;
