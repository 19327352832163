import { Outlet, Link } from "react-router-dom";
import './Layout.css'
function Layout() {
    return (
        <>
        <div className="layout-container">
           <div className="logo" >
                <img src="/images/logo.jpeg" alt="logo" />
          </div>
          <div>
            <h1>New mission of hope haitian church inc</h1>
          </div>
            <nav>
                <ul>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/about">About us</Link></li>
                    <li><Link to="/">Home</Link></li>
                </ul>
            </nav>
            </div>
            <Outlet />
        </>
    );
}

export default Layout;