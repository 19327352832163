import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout/Layout';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import About from "./About/About";
import Footer from "./Footer/Footer";

function App() {
 return (
  <>
   <BrowserRouter>
    <Routes>
     <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="about" element={<About />} />
      </Route>
      </Routes>
      </BrowserRouter>
   <Footer />
  </>
 );
}

export default App;
