
import Carousel from '../Carousel/Carousel';
import Info from '../Info/Info';
import Quote from '../Quote/Quote';
import Volunteers from '../Volunteers/Volunteers'

function Home() {
 return (
  <>
   <Carousel />
   <Quote />
   <Info />
   <Volunteers />
  </>
 );
}

export default Home;
