import { Link } from "react-router-dom";
import './Volunteers.css'
function Quote() {
 const handleClick = () => {
  window.open("https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=N94X5GETDF3MA", '_blank');
 };
 return (
  <>
   <section className='volunteers-container'>
    <div className='donate'>
     <h1>Donate</h1>
     <button onClick={handleClick} className='button'>
      <span className='label-1'>Pay</span><span className='label-2'>Pal</span>
     </button>
    </div>
    <div className='volunteers-picture1-container'>
     <h2 className='volunteers-heading-label'>Volunteers</h2>
     <div className='volunteers-picture1'>
       <img className='volunteer-image' src='/images/image-1.jpeg' alt=''/>
      
       <img className='volunteer-image1' src='/images/image-2.jpeg' alt=''/>
      
      
       <img className='volunteer-image1' src='/images/image-3.jpeg' alt=''/>
     
     </div>
    </div>
    <div className='volunteers-picture2'>
     <div className='volunteers-picture-2'>
    
       <img className='volunteer-image1' src='/images/image-4.jpeg' alt=''/>
      
     
       <img className='volunteer-image1' src='/images/image-5.jpeg' alt=''/>
     

     </div>
     <Link to="/contact"  className='btn-volunteer'>Join us</Link>
    </div>
   </section>
  </>
 );
}

export default Quote;